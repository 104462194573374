import React from "react";
import Notification from "react-web-notification";
import Helmet from "react-helmet";
import { Branding } from "~/branding";

//allow react dev tools work
window.React = React;

export default class NotificationWrapper extends React.Component {
  state = {
    ignore: true,
    title: "",
    notify: false,
  };
  // messageCount = 0;
  attentionMessage = "New Message";
  blinkEvent: any = null;
  onVisibilityChange = () => {
    var isPageActive = !document.hidden;
    if (!isPageActive) {
      // this.messageCount = this.props.totalUnread;
      this.blink();
    } else {
      // this.messageCount = this.props.totalUnread;
      document.title = `${
        this.props.totalUnread ? `Inbox (${this.props.totalUnread}) ` : ""
      }${this.props.app_name ? this.props.app_name : Branding.APP_NAME}`;
      clearInterval(this.blinkEvent);
      this.setState({ ...this.state, notify: false });
    }
  };
  blink = () => {
    this.blinkEvent = setInterval(() => {
      if (document.title === this.attentionMessage) {
        document.title = `${
          this.props.totalUnread ? `Inbox (${this.props.totalUnread}) ` : ""
        }${this.props.app_name ? this.props.app_name : Branding.APP_NAME}`;
      } else {
        if (this.props.totalUnread || this.state.notify)
          document.title = this.attentionMessage;
      }
    }, 100);
  };

  componentDidMount() {
    document.addEventListener("visibilitychange", this.onVisibilityChange);
  }

  componentWillUnmount() {
    document.removeEventListener("visibilitychange", this.onVisibilityChange);
  }

  handlePermissionGranted() {
    this.setState({
      ignore: false,
    });
  }
  handlePermissionDenied() {
    this.setState({
      ignore: true,
    });
  }
  handleNotSupported() {
    console.log("not supported");
    this.setState({
      ignore: true,
    });
  }

  show = ({ sender }) => {
    console.log("====================== Enter Nortit");
    if (this.state.ignore) {
      return;
    }

    const title = `${
      this.props.app_name ? this.props.app_name : Branding.APP_NAME
    }`;
    const body = `New message from ${sender}`;
    // const icon = "http://localhost:1234/favicon-32x32.43b30f10.png";
    const icon =
      "https://messageviewminio-staging.brightlink.com/lol/favicon.png";

    const options = {
      body: body,
      icon: icon,
      lang: "en",
      dir: "ltr",
    };
    this.setState({
      title: title,
      options: options,
      notify: true,
    });
    console.log("====================== Nortit");
    // if (document.hidden) {
    //   this.messageCount += 1;
    // }
  };

  // increaseMessageCount = () => {
  // if (document.hidden) {
  //   this.messageCount += 1;
  //   this.setState({
  //     ...this.state,
  //     notify: true,
  //   });
  // }
  // };

  render() {
    return (
      <div style={{ display: "none" }}>
        <Helmet>
          <title>{`${
            this.props.totalUnread ? `Inbox (${this.props.totalUnread}) ` : ""
          }${
            this.props.app_name ? this.props.app_name : Branding.APP_NAME
          }`}</title>
        </Helmet>
        <Notification
          ignore={this.state.ignore && this.state.title !== ""}
          notSupported={this.handleNotSupported.bind(this)}
          onPermissionGranted={this.handlePermissionGranted.bind(this)}
          onPermissionDenied={this.handlePermissionDenied.bind(this)}
          timeout={5000}
          title={this.state.title}
          options={this.state.options}
          askAgain
        />
      </div>
    );
  }
}
